<script setup lang="ts">
import {Carousel, Navigation, Slide} from "vue3-carousel";

const props = defineProps({
  topCruiselines: {
    type: Object,
    required: true
  },
  cruiselines: {
    type: Object,
    required: true
  }
})
const cruiselinesAll = [...Object.values(props.topCruiselines), ...Object.values(props.cruiselines)];
</script>

<template>
  <div
    class="container-slider-cruiselines"
  >
    <carousel
      class="homepage--carousel"
      :items-to-show="2"
      :touchDrag="true"
      :mouseDrag="false"
      :modelValue="$device.isMobile ? 0 : 2"
      :breakpoints="{
        768: {
          itemsToShow: 6,
        },
        992: {
          itemsToShow: 7,
        },
        1200: {
          itemsToShow: 9,
        },
      }"
      :wrap-around="true"
    >
      <slide
        v-for="cruiseline in cruiselinesAll"
        :key="cruiseline.image.url"
      >
        <NuxtLinkLocale
          v-if="cruiseline.slug && cruiseline.image.url"
          :to="{ name: 'cruiselines-slug', params: { slug: cruiseline.slug } }"
        >
          <NuxtImg
            height="90px"
            format="webp"
            :src="cruiseline.image.url"
            :alt="cruiseline.image.alt"
            loading='lazy'
          />
        </NuxtLinkLocale>
      </slide>

      <template #addons>
        <navigation>
          <template #prev>
            <UiIconsArrowWithLine direction="left"/>
          </template>
          <template #next>
            <UiIconsArrowWithLine direction="right"/>
          </template>
        </navigation>
      </template>
    </carousel>
  </div>
</template>

<style lang="scss" scoped>
.container-slider-cruiselines {
  background: var(--color-primary-white);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 100%;
    padding: 0.5em 1.1em;
    transition: all 0.3s ease;

    @media (min-width: map-get($grid-breakpoints, md)) {
      padding: 0.5em 0.70em;
      height: 90px;
    }

    &:hover {
      scale: (0.8);
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
