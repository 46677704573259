<script setup lang="ts">
const { experimentVersion } = useABTesting()

defineProps({
  title: {
    type: String,
    required: true,
    default: '',
  },
  subtitle: {
    type: String,
    required: true,
    default: '',
  },
  backgroundVideoPath: {
    type: String,
    required: true,
    default: '',
  },
  mobileBackgroundImage: {
    type: String,
    required: false,
    default: '',
  },
  destinationsContent: {
    type: Object,
    required: true,
  },
})

const imageExtensionRegex = /\.(jpe?g|png|webp|svg|tiff|ico|dvu)$/i;
</script>

<template>
  <div class="home-header-container v2-search-block">
    <NuxtImg
      v-if="
        (mobileBackgroundImage && mobileBackgroundImage.includes('.gif') && $device.isMobile)
        || (backgroundVideoPath && backgroundVideoPath.includes('.gif') && $device.isDesktopOrTablet)
      "
      class="hero-background"
      fetchPriority="high"
      format="gif"
      sizes="100vw sm:50vw md:400px lg:100vw"
      width="600"
      :alt="title"
      :src="getStrapiMedia($device.isMobile ? mobileBackgroundImage : backgroundVideoPath)"
    />
    <NuxtImg
      v-else-if="
        (mobileBackgroundImage && imageExtensionRegex.test(mobileBackgroundImage) && $device.isMobile)
        || (backgroundVideoPath && imageExtensionRegex.test(backgroundVideoPath) && $device.isDesktopOrTablet)
      "
      class="hero-background"
      fetchPriority="high"
      format="webp"
      sizes="100vw sm:50vw md:600px lg:100vw"
      :alt="title"
      :src="getStrapiMedia($device.isMobile ? mobileBackgroundImage : backgroundVideoPath)"
    />
    <NuxtVideo
      v-else-if="
        (mobileBackgroundImage && $device.isMobile)
        || (backgroundVideoPath && $device.isDesktopOrTablet)
      "
      :src="$device.isMobile ? mobileBackgroundImage : backgroundVideoPath"
      provider="strapi"
      class="hero-background"
      :autoplay="true"
      :loop="true"
      :muted="true"
      :controls="false"
      :playsinline="true"
    />
    <div class="content">
      <UiElementsWaveTitle class="home-page-title">
        <h1>
          {{ title }}
        </h1>
      </UiElementsWaveTitle>
      <p>
        {{ subtitle }}
      </p>
    </div>
<!--    <SearchV2BlockElement-->
<!--      v-if="experimentVersion === 'v2'"-->
<!--      :destinations-content="destinationsContent"-->
<!--    />-->
<!--    <SearchBlock-->
<!--      v-else-->
<!--      :destinations-content="destinationsContent"-->
<!--    />-->
    <SearchBlock
      :destinations-content="destinationsContent"
    />
  </div>
</template>

<style lang="scss">
.home-header-container {
  position: relative;
  height: 350px;
  background-color: var(--color-grey-450);

  @media (min-width: map-get($grid-breakpoints, lg)) {
    height: 520px;
  }

  video, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-white);
    font-family: var(--font-secondary), sans-serif;

    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
      padding: 1em;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      left: $space-2xl;
      transform: translateY(-50%);
    }

    .home-page-title {
      max-width: 700px;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        align-items: start;
      }

      h1 {
        font-size: 1.9rem;
        text-transform: none;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          text-align: left;
          font-size: 3.5rem;
          line-height: 1;
        }
      }
    }

    p {
      margin: 0;
      font-style: italic;
      text-align: center;
      padding-top: 0.5em;
      font-size: 1.5rem;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-top: $space-sm;
        font-size: 2rem;
        text-align: start;
      }
    }
  }
}

.home-header-container {
  &.v2-search-block {
    position: relative;
    align-items: center;
    height: 100%;
    background-color: var(--color-grey-450);

    @media (min-width: map-get($grid-breakpoints, lg)) {
      height: 520px;
    }

    .hero-background {
      position: absolute;
      top: 0;
      left: 0;
    }

    .content {
      position: initial;
      color: var(--color-white);
      font-family: var(--font-secondary);
      width: 100%;
      transform: translate(0,0);
      padding: 0.5em;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        position: absolute;
        top: 50%;
        left: 2.8em;
        transform: translateY(-50%);
        width: calc(100% - 2.8em);
      }
    }
  }
}
</style>
